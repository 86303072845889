body {
  padding: 2em;
  text-align: center;
  background-size: cover;
  width: 100%;
  display: table-row;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-origin: initial;
  background-clip: initial;
  background-color: darkslategray;
  background-image: url("/img/background.jpg");
  background-image: -webkit-image-set(url("/img/background.webp") 1x);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
