.container {
    /* absolute center .splash within .splash-container */
    width: auto;
    height: auto;
    margin:  auto;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    /* text-align: center; */
    /* text-transform: uppercase; */
}

.logo {
    width: auto;
    /* height: 50%; */
    /* position: fixed; */
    /* top: 0; left: 0; bottom: auto; right: 0; */
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
}

.logo > img {
    height: 10em;
    width: 10em;
}

.footer {
    margin-top: 2em;
    margin-bottom: 1em;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
}